<template>
  <div class="blacklist-page">
    <vuestic-widget>
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Lead Forms</span>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-primary btn-sm" @click="onAddLeadFormClick()">
              Add Form <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="pb-2 mb-3">
            </div>
            <div class="d-flex justify-content-center" v-if="isLoading">
              <span class="atom-spinner-wrapper">
                <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
              </span>
            </div>
            <div class="col-md-12" v-else>
              <datatable v-bind="ipTableOptions" class="le-datatable" />
            </div>
          </div>
        </div>
      </div>
    </vuestic-widget>

    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleDeleteLeadForm" @cancel="closeModalDelete" okText="Delete"
      cancelText="Cancel" :processing="isLoadingDelete" okClass="btn btn-danger">
      <span slot="title" class="text-danger font-weight-bold">Delete Lead Form</span>

      <div>Confirm you want to delete the lead form for <span v-if="selectedDeleteForm" class="text-danger"><b>{{
        selectedDeleteForm.name }}</b></span> ?</div>
    </vuestic-modal>
  </div>
</template>
<script>
import SearchInput from '../../common/SearchInput'
import Action from './Action'
import TdBoolean from '@/components/common/tables/comps/td-Boolean'
import TdToggle from '@/components/common/tables/comps/td-Toggle'
export default {
  components: {
    SearchInput,
    Action,
    TdBoolean,
    TdToggle
  },
  created() {
    this.ipTableOptions.xprops.eventbus.$on('toggleActive', this.toggleActive)
  },
  data() {
    return {
      isLoading: false,
      isOpenModalDelete: false,
      isLoadingDelete: false,
      ipTableOptions: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [5, 10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true, visible: true },
            { title: "Name", field: "name", label: "Name", tdClass: "center", sortable: true, visible: true },
            { title: 'Active', field: 'is_active', tdComp: TdToggle, sortable: true, },
            { title: '', tdComp: Action, visible: 'true', tdClass: 'center', },
          ]
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {},
        xprops: {
          eventbus: new Vue()
        }
      },
      selectedDeleteForm: null,
    }
  },
  watch: {
    'ipTableOptions.query': {
      handler() {
        this.loadData()
      },
      deep: true
    },
  },
  mounted() {
    this.ipTableOptions.xprops.eventbus.$on('openDeleteModal', row => {
      this.openModalDelete(row)
    })
  },
  methods: {
    onAddLeadFormClick() {
      this.$router.push({ name: 'business.lead-forms.create' });
    },
    loadData() {
      this.isLoading = true
      const { query } = this.ipTableOptions;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
      }
      this.$store.dispatch('leadForm/allLeadForms', param)
        .then((res) => {
          this.ipTableOptions.data = res.data
          this.ipTableOptions.total = res.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
          this.$router.push({ name: 'business.installs.active' });
        })
    },
    openModalDelete(leadForm) {
      this.isOpenModalDelete = true
      this.selectedDeleteForm = leadForm
    },
    closeModalDelete() {
      this.isOpenModalDelete = false
      this.selectedDeleteForm = null
    },
    handleDeleteLeadForm() {
      this.isLoadingDelete = true
      this.$store.dispatch('leadForm/deleteLeadForm', this.selectedDeleteForm)
        .then(() => {
          this.closeModalDelete()
          this.loadData()
          this.isLoadingDelete = false
        })
        .catch(() => {
          this.isLoadingDelete = false
        })
    },
    toggleActive(args) {
      this.$store.dispatch('leadForm/toggleActiveLeadForm', { id: args.id })
        .then(() => {
        })
        .catch(() => {
        })

    },
  },
}
</script>
<style lang="scss">
.blacklist-page {
  .widget-body {
    padding: 0;
  }

  .nav-item {
    min-width: 120px;
    text-align: center;
  }

  #embedCode {
    border-bottom: none;
  }
}
</style>
<template>
  <div class="center">
    <AppDropdownSelect
      :options="options"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>
import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect'
export default {
  components: {
    AppDropdownSelect,
  },
  props: ['row', 'nested', 'xprops'],
  data () {
    return {
      options: [
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete' },
      ],
    }
  },
  methods: {
    openDeleteModal () {
      this.xprops.eventbus.$emit('openDeleteModal', this.row);
    },
    openEditModal () {
      this.xprops.eventbus.$emit('openEditModal', this.row);
    },
    onItemClick (item) {
      switch (item.value) {
        case 'edit':
          this.$router.push({ name: 'business.lead-forms.update', params: { id: this.row.id } })
          break;
        case 'delete':
        default:
          this.xprops.eventbus.$emit('openDeleteModal', this.row);
          break;
      }
    },
  }
}
</script>

<style lang="scss">
  .more-custom-popover {
    ul li {
      font-size: 17px;
      line-height: 30px !important;
      &:hover {
        border-bottom: 1px solid $rb-blue;
      }
    }
  }
</style>
<style scoped lang="scss">
  .more-icon {
    display: block;
    padding: 0 5px;
  }
  .custom-popover {
    ul.li {
      font-size: 17px;
      line-height: 30px !important;
    }
  }
</style>